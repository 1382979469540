<template>
  <section id="challenge-setup" class="text-white d-flex flex-column">
    <div class="commet-background flex-fill pt-4 pb-5 px-4 d-flex flex-column">
      <SectionTitleHeader :title="title" color="general-knowledge" class="pt-5 mb-5" />
      <main class="d-flex flex-column justify-content-center align-items-center mb-5" data-aos="zoom-in" data-aos-delay="1000">
        <img alt="Challenge Mode Image" :src="challengeModeIcon" width="242" height="172" />
        <p class="font-italic legend --big">{{ description }}</p>
      </main>
      <div class="d-flex justify-content-space-between">
        <button type="button" class="btn bg-green-neon flex-fill rounded-button" data-aos="fade-left" data-aos-offset="-100" data-aos-delay="500" @click="action">
          {{ CTA }}
        </button>
      </div>
    </div>
  </section>
</template>
<script>
import SectionTitleHeader from '@/components/SectionTitleHeader.vue';

export default {
  components: { SectionTitleHeader },
  methods: {
    action() {
      const action = this.$route.meta.as === 'host' ? 'resume-challenge' : 'join-challenge';
      alert(
        JSON.stringify(
          {
            action,
            as: this.$route.meta.as,
            id: this.$route.params.id,
            nickname: this.$store.state.challenge.nickname
          },
          null,
          2
        )
      );
    }
  },
  computed: {
    challengeModeIcon() {
      return require('@/assets/images/swords.png');
    },
    description() {
      return 'Click the button below to start the game and answer your first challenge question!';
    },
    title() {
      return this.$route.meta.as == 'host' ? 'Challenge created!' : 'Joined challenge!';
    },
    CTA() {
      return 'Start game!';
    }
  },
  mounted() {
    if (!this.$store.state.challenge.session_data) {
      this.$router.push({ name: 'challenge-' + this.$route.meta.as, params: this.$route.params });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/custom-vars/_colors.scss';

#challenge-setup {
  background: $gradient-challenge-mode;
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.4);
  min-height: 100%;
  main {
    flex-grow: 2;
  }
  button.--outlined {
    border-color: $green-neon;
  }
}
</style>
